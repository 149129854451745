body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

/* PDF viewer overides */
:not(:root):fullscreen{
  background:#464646 !important;
}
.rpv-full-screen-exit-button-inner{
  background: transparent !important;
}
.rpv-full-screen-exit-button{
  top:0 !important;
  padding:24px !important;
  z-index: 100 !important;
}
.rpv-full-screen-exit-button-inner .rpv-core-button{
  background:rgba(0,0,0,.6);
  width:42px;
  height:42px;
  border-radius:20px;
  bottom:200px !important
}
.rpv-full-screen-exit-button-inner .rpv-core-button:hover{
  background:black !important
}
.linkAnnotation{
  display: none;
}
.react-pdf__Document{
  position: relative;
  min-height: 90px;
  background:white;
}
.react-pdf__message{
  display: none;
}
.rpv-search-text-highlight-current {
  outline: 2px dashed #00449e;
}
.rpv-default-layout-toolbar{
  height:56px !important;
  background: rgb(30,30,30) !important;
  padding:9px 12px !important;
}
.rpv-core__inner-page{
  background:#464646 !important;
  padding-top:32px !important;
  padding-inline: 0 !important;
  margin-top: 50px 
}
.rpv-core__inner-page:first-child /*{*/
  /* padding-top: 96px !important; */
/*}*/
.rpv-core__inner-page:last-child {
  padding-bottom: 96px !important;
}
.rpv-default-layout-body{
  background:#464646 !important;
}
.rpv-default-layout-main{
  background:#464646 !important;
}
.rpv-core__doc-loading {
  background:#464646 !important;
}
.rpv-search__highlight {
  background-color: #DDFF66 !important;
}
.rpv-search__highlight--current {
  background-color: #00C128 !important;
}
.rpv-core__text-layer {
  opacity: 0.5 !important;
}
.rpv-core-icon {
  stroke: #DCE6E6 !important;
  stroke-width: 2 !important;
  width:18px !important;
  height:18px !important;
}
.rpv-core-tooltip-body{
  background:#DDFF66 !important;
  color:#1E1E1E !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  box-shadow: 0px 2px 10px 0 rgba(0,0,0,.4) !important;
}
.rpv-core-tooltip-body-arrow{
  display:none !important
}
.rpv-core-tooltip-body-content{
  padding:2px 8px !important;
}
.rpv-core-button:focus{
 outline:none !important;
}
.rpv-core-button:hover > .rpv-core-icon{
  stroke:#C8F500 !important;
}
.rpv-zoom-popover-target{
  background: white;
  padding:4px 10px !important;
  border-radius: 4px;
  color: black !important;
}
.rpv-core-menu-item-label{
  background: transparent;
  color: black;
}
.rpv-toolbar-item{
  color: white;
}
.rpv-page-navigation__current-page-input {
  width: 60px !important;
}
.rpv-page-navigation__current-page-input .rpv-core__textbox {
  border: none !important;
  padding: 6px 10px !important;
  width: 100% !important;
  border-radius: 4px !important;
  font-weight: 600;
  margin-right:2px;
  font-size:14px
}
.rpv-page-navigation__current-page-input .rpv-core__textbox:focus {
  outline:none
}
